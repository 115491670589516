<template>
  <div>
    <el-card shadow="never" class="card-inside normal">
      <el-row :gutter="15" type="flex" class="flex-wrap">
        <el-col :span="24">
          <el-row :gutter="15" type="flex" class="flex-wrap">
            <el-col :span="24" :md="12">
              <el-form-item label="Logo 1">
                <el-row :gutter="10" type="flex" class="flex-wrap">
                  <el-col :span="24" :md="16">
                    <el-input
                      placeholder="Upload Logo 1"
                      v-model="form.logo1"
                      @keypress.native="ignoreKey($event)"
                      @clear="delImage(1)"
                      clearable
                    >
                    </el-input>
                  </el-col>
                  <el-col :span="24" :md="8">
                    <el-form-item class="mg-b-less">
                      <el-upload
                        action="#"
                        :auto-upload="false"
                        :on-change="
                          (file, fileList) =>
                            uploadLogo(file, fileList, `logo1`)
                        "
                        accept="image/jpeg,image,image/png"
                      >
                        <el-button class="btn-upload w-100">
                          <span>Click to upload </span>
                        </el-button>
                        <span slot="file"></span>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="รายละเอียด Logo1">
                <el-input v-model="form.desLogo1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="Logo 2">
                <el-row :gutter="10" type="flex" class="flex-wrap">
                  <el-col :span="24" :md="16">
                    <el-input
                      placeholder="Upload Logo 2"
                      v-model="form.logo2"
                      @keypress.native="ignoreKey($event)"
                      @clear="delImage(2)"
                      clearable
                    >
                    </el-input>
                  </el-col>
                  <el-col :span="24" :md="8">
                    <el-form-item class="mg-b-less">
                      <el-upload
                        action="#"
                        :auto-upload="false"
                        :on-change="
                          (file, fileList) =>
                            uploadLogo(file, fileList, `logo2`)
                        "
                        accept="image/jpeg,image,image/png"
                      >
                        <el-button class="btn-upload w-100">
                          <span>Click to upload </span>
                        </el-button>
                        <span slot="file"></span>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="รายละเอียด Logo2">
                <el-input v-model="form.desLogo2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="Logo 3">
                <el-row :gutter="10" type="flex" class="flex-wrap">
                  <el-col :span="24" :md="16">
                    <el-input
                      placeholder="Upload Logo 3"
                      v-model="form.logo3"
                      @keypress.native="ignoreKey($event)"
                      @clear="delImage(3)"
                      clearable
                    >
                    </el-input>
                  </el-col>
                  <el-col :span="24" :md="8">
                    <el-form-item class="mg-b-less">
                      <el-upload
                        action="#"
                        :auto-upload="false"
                        :on-change="
                          (file, fileList) =>
                            uploadLogo(file, fileList, `logo3`)
                        "
                        accept="image/jpeg,image,image/png"
                      >
                        <el-button class="btn-upload w-100">
                          <span>Click to upload </span>
                        </el-button>
                        <span slot="file"></span>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="รายละเอียด Logo3">
                <el-input v-model="form.desLogo3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Company name (ชื่อบริษัท)" prop="companyName">
            <el-select
              id="select-companyName"
              v-model="form.companyName"
              filterable
              clearable
              @keydown.native="autoChage"
              @clear="autoChage"
              @change="setAddress"
              placeholder="Company name"
              :loading="loadingAuto"
              :disabled="typePage == 'edit' ? true : false"
            >
              <el-option
                class="infinite-list-item"
                v-for="item in refRequisition"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Registered Address (ที่อยู่ใบสมัคร)">
            <el-input v-model="form.registerAddress" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item
            label="Certificated Scope (ขอบเขตใบรับรอง)"
            prop="certificateScope"
          >
            <el-input
              v-model="form.certificateScope"
              :disabled="form.certificateScope != '' ? false : true"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Business Location" prop="businessLocation">
            <el-input
              v-model="form.businessLocation"
              placeholder="Business Location"
              @blur="form.businessLocation = form.businessLocation.trim()"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Production Address">
            <el-input
              v-model="form.productionAddress"
              placeholder="Production Address"
              @blur="form.productionAddress = form.productionAddress.trim()"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Standard (มาตรฐาน)" prop="standard">
            <el-input
              v-model="form.standard"
              placeholder="Standard"
              @blur="form.standard = form.standard.trim()"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="คำอธิบายมาตรฐาน" prop="standardDescription">
            <el-input
              v-model="form.standardDescription"
              placeholder="คำอธิบายมาตรฐาน"
              @blur="form.standardDescription = form.standardDescription.trim()"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item
            label="Registration No. (เลขที่ลงทะเบียน)"
            prop="registrationNo"
          >
            <el-input
              v-model="form.registrationNo"
              placeholder="Registration No."
              @blur="form.registrationNo = form.registrationNo.trim()"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item
            label="First Issuing Date (วันที่ออกครั้งแรก)"
            prop="firstIssuingDate"
          >
            <el-date-picker
              class="custom-date-picker"
              placeholder="First Issuing Date"
              v-model="form.firstIssuingDate"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Issuing Date (วันที่ออก)" prop="issuingDate">
            <el-date-picker
              class="custom-date-picker"
              placeholder="Issuing Date"
              v-model="form.issuingDate"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item
            label="Expiration Date (วันที่หมดอายุ)"
            prop="expiationDate"
          >
            <el-date-picker
              class="custom-date-picker"
              placeholder="Expiration Date"
              v-model="form.expiationDate"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item
            label="Altering Date (วันที่เปลี่ยนแปลง)"
            prop="alteringDate"
          >
            <el-date-picker
              class="custom-date-picker"
              placeholder="Altering Date"
              v-model="form.alteringDate"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Isued By (ออกโดย)" prop="issuedBy">
            <el-row :gutter="15" type="flex" class="flex-wrap">
              <el-col :span="24" :md="16">
                <el-input
                  placeholder="Upload ลายเซ็น"
                  v-model="form.issuedBy"
                  disabled
                >
                </el-input>
              </el-col>
              <el-col :span="24" :md="8">
                <el-form-item class="mg-b-less">
                  <el-popover
                    popper-class="popover-upload"
                    placement="bottom-end"
                    width="200"
                    trigger="manual"
                    v-model="popoverUpload"
                  >
                    <div v-loading="loadingPopover">
                      <i class="el-icon-close" @click="openPopover()"></i>
                      <el-upload
                        action="#"
                        list-type="picture-card"
                        :auto-upload="false"
                        :file-list="fileList"
                        :on-change="
                          (file, fileList) => uploadDocuments(file, fileList)
                        "
                        accept="image/jpeg,image,image/png"
                      >
                        <i slot="default" class="el-icon-plus"></i>

                        <div
                          slot="file"
                          slot-scope="{ file }"
                          :class="form.issuedBy == file.name ? 'active' : ''"
                        >
                          <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url"
                            alt=""
                          />
                          <span class="el-upload-list__item-actions">
                            <span
                              class="el-upload-list__item-preview"
                              @click="handlePictureCardPreview(file)"
                            >
                              <i class="el-icon-view"></i>
                            </span>
                            <span @click="activeImage(file)">
                              <i class="el-icon-document-checked"></i>
                            </span>
                            <span
                              class="el-upload-list__item-delete"
                              @click="handleRemove(file)"
                            >
                              <i class="el-icon-delete"></i>
                            </span>
                          </span>
                        </div>
                      </el-upload>
                    </div>

                    <el-button
                      slot="reference"
                      class="btn-upload w-100"
                      @click="openPopover()"
                    >
                      <span>Click to upload </span>
                    </el-button>
                  </el-popover>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
export default {
  components: {},
  computed: {
    ...mapState({
      _token: (state) => state._token,
      setCsrf: (state) => state.setCsrf,
    }),
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    typePage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          return time.getTime() < today.setDate(today.getDate() - 1);
        },
      },
      dialogImageUrl: "",
      loadingPopover: false,
      dialogVisible: false,
      popoverUpload: false,
      loadingAuto: false,
      fileList: [],
      delFiles: [],
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
      refRequisition: [],
    };
  },
  async created() {
    await this.getRefRequisition();
  },
  methods: {
    async getRefRequisition() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`matching/assessment/cer`)
        .then(async (res) => {
          if (res.data.success) {
            this.refRequisition = await res.data.obj.map((row) => {
              return {
                _id: row.certificationId,
                address: row.address,
                value: row.companyName,
                companyType: row.companyType,
              };
            });
            if (this.typePage == "edit") {
              await this.setAddress();
            }
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingPopover = false;
          this.loadingAuto = false;
        });
    },
    handleRemove(file) {
      if (file._id != undefined) {
        this.delFiles = file.name;
        this.deleteFiles();
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    activeImage(file) {
      if (this.form.issuedBy == file.name) {
        this.form.issuedBy = "";
      } else {
        this.form.issuedBy = file.name;
      }
    },
    openPopover() {
      this.popoverUpload = !this.popoverUpload;
      if (this.popoverUpload) {
        this.loadingPopover = true;
        this.getSignature();
      } else {
        this.fileList = [];
      }
    },
    getSignature() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`certification/signature`)
        .then((res) => {
          if (res.data.success) {
            this.fileList = res.data.obj.reduce((result, row) => {
              var obj = {};
              let setUrl = this.imageUrl + "signature/" + row.file;
              obj = {
                name: row.file,
                url: setUrl,
                _id: row._id,
              };
              result.push(obj);
              return result;
            }, []);
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingPopover = false;
        });
    },
    async uploadDocuments(file, fileList) {
      let typeThis = file.raw.name.split(".");
      let type = file.raw.type.split("/");
      let indexOfFile = typeThis.length > 1 ? typeThis.length - 1 : 1;
      if (typeThis[indexOfFile] != "json") {
        if (file.size < 25000000) {
          if (
            type[indexOfFile] == "jpeg" ||
            type[indexOfFile] == "png" ||
            type[indexOfFile] == "jpg"
          ) {
            let obj = {
              name: file.name,
              file: file,
            };

            await this.uploadFile(obj);
          } else {
            this.$message({
              message: "กรุณาเลือกไฟล์นามสกุลเป็น JPG , JPEG หรือ PNG เท่านั้น",
              type: "error",
              duration: 4000,
            });
            let index = fileList.findIndex((a) => a.uid == file.uid);
            fileList.splice(index, 1);
          }
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25 MB",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        this.fileList = JSON.parse(JSON.stringify(this.fileList));
        this.$message({
          message: "ไม่สามารถอัพโหลดไฟล์นามสกุล JSON ได้",
          type: "error",
          duration: 4000,
        });
      }
    },
    async uploadFile(obj) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.setCsrf,
        },
      };
      var formData = new FormData();

      formData.append("filesUpload", obj.file.raw);
      formData.append("_csrf", this.setCsrf);

      await HTTP.post(`certification/signature`, formData, config)
        .then((res) => {
          if (res.data.success) {
            let setUrl = this.imageUrl + "signature/" + res.data.obj.file;

            this.fileList.push({
              name: res.data.obj.file,
              url: setUrl,
              _id: res.data.obj._id,
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    async deleteFiles() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      await HTTP.post(`certification/delete/signature`, {
        delFiles: this.delFiles,
      })
        .then((res) => {
          if (res.data.success) {
            let index = this.fileList.findIndex((f) => f.name == this.delFiles);
            this.fileList.splice(index, 1);
            this.delFiles = "";
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    async uploadLogo(file, fileList, typeLogo) {
      let typeThis = file.raw.name.split(".");
      let type = file.raw.type.split("/");
      let indexOfFile = typeThis.length > 1 ? typeThis.length - 1 : 1;
      if (typeThis[indexOfFile] != "json") {
        if (file.size < 25000000) {
          if (
            type[indexOfFile] == "jpeg" ||
            type[indexOfFile] == "png" ||
            type[indexOfFile] == "jpg"
          ) {
            let setTypeLogo = "";
            if (typeLogo == "logo1") {
              this.form.logo1 = file.name;
              setTypeLogo = 1;
            } else if (typeLogo == "logo2") {
              this.form.logo2 = file.name;
              setTypeLogo = 2;
            } else if (typeLogo == "logo3") {
              this.form.logo3 = file.name;
              setTypeLogo = 3;
            }
            let obj = {
              name: file.name,
              file: file,
              typelogo: setTypeLogo,
            };
            let index = this.form.fileLogo.findIndex(
              (a) => a.typelogo == setTypeLogo
            );
            if (index >= 0) {
              this.form.fileLogo.splice(index, 1);
            }
            this.form.fileLogo.push(obj);
          } else {
            this.$message({
              message: "กรุณาเลือกไฟล์นามสกุลเป็น JPG , JPEG หรือ PNG เท่านั้น",
              type: "error",
              duration: 4000,
            });
            let index = fileList.findIndex((a) => a.uid == file.uid);
            fileList.splice(index, 1);
          }
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25 MB",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        this.fileList = JSON.parse(JSON.stringify(this.fileList));
        this.$message({
          message: "ไม่สามารถอัพโหลดไฟล์นามสกุล JSON ได้",
          type: "error",
          duration: 4000,
        });
      }
    },
    delImage(number) {
      let findBySuccess = this.form.fileLogo.find(
        (a) => typeof a.file == "undefined" && a.typelogo == number
      );

      if (findBySuccess != undefined) {
        this.form.delFiles.push({ number: number, name: findBySuccess.name });
      }

      let index = this.form.fileLogo.findIndex((a) => a.typelogo == number);
      if (index >= 0) {
        this.form.fileLogo.splice(index, 1);
      }
    },
    autoChage() {
      let debounce = null;
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        this.getRefRequisition();
      }, 600);
    },
    setAddress() {
      this.form.certificateScope = "";
      if (this.form.companyName != "") {
        let find = this.refRequisition.find(
          (com) => com.value == this.form.companyName
        );
        this.form.registerAddress = find.address;
        this.form.certificationId = find._id;
        if (this.typePage != "edit") {
          find.companyType.forEach((element, index) => {
            this.form.certificateScope += element.scoped;
            this.form.certificateScope +=
              index != find.companyType.length - 1 ? ", " : "";
          });
        }
      } else {
        this.form.registerAddress = "";
        this.form.certificationId = "";
        this.form.certificateScope = "";
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 3, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`
      );
    },
  },
};
</script>
