<template>
  <div id="preview-pdf">
    <h1 class="text-upper" style="margin-top: 50px">
      certification certificate
    </h1>
    <div class="detail-container">
      <h2>
        {{ form.companyName }} <br />
        <span class="font-18">
          {{ form.standardDescription }}<br />
          {{ form.standard }}
        </span>
      </h2>
      <div class="group">
        <p class="mg-less">
          <span class="mg-r-1">Registered Address : </span>
          {{ form.registerAddress }}
        </p>
        <p class="mg-less">
          <span class="mg-r-1">Business Location : </span>
          {{ form.businessLocation }}
        </p>
        <p class="mg-less" v-if="form.productionAddress">
          <span class="mg-r-1">Production Address : </span>
          {{ form.productionAddress }}
        </p>

        <p class="mg-t-3">
          <span class="mg-r-1">Certificated Scope : </span>
          <span> {{ form.certificateScope }}</span>
        </p>
      </div>
    </div>
    <el-row :gutter="20" class="custom-signature">
      <el-col :span="24" :md="12">
        <p>
          <span class="mg-r-1">Registration No. : </span>
          {{ form.registrationNo }}
        </p>
        <p>
          <span class="mg-r-1">First Issuing Date : </span>
          {{ form.firstIssuingDate | formatDateEN }}
        </p>
        <p>
          <span class="mg-r-1">Issuing Date : </span>
          {{ form.issuingDate | formatDateEN }}
        </p>
        <p v-if="form.alteringDate != '' && form.alteringDate != null">
          <span class="mg-r-1">Altering Date : </span>
          {{ form.alteringDate | formatDateEN }}
        </p>
        <p>
          <span class="mg-r-1">Expiration Date : </span>
          {{ form.expiationDate | formatDateEN }}
        </p>
      </el-col>
      <el-col
        :span="24"
        :md="12"
        class="box-right-signature"
        v-if="form.issuedBy"
      >
        <div class="inside-signature">
          <img
            :src="imageUrl + 'signature/' + form.issuedBy"
            class="img-signature"
          />
          <div>
            <span class="name">Isued By :</span>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="box-logo" v-if="viewOnly">
      <div>
        <img
          :src="imageUrl + 'certification/' + form._id + '/' + form.logo1"
          v-if="form.logo1"
        />
        <p v-if="form.logo1 && form.desLogo1" class="font-14">
          {{ form.desLogo1 }}
        </p>
      </div>
      <div>
        <img
          :src="imageUrl + 'certification/' + form._id + '/' + form.logo2"
          v-if="form.logo2"
        />
        <p v-if="form.logo2 && form.desLogo2" class="font-14">
          {{ form.desLogo2 }}
        </p>
      </div>
      <div>
        <img
          :src="imageUrl + 'certification/' + form._id + '/' + form.logo3"
          v-if="form.logo3"
        />
        <p v-if="form.logo3 && form.desLogo3" class="font-14">
          {{ form.desLogo3 }}
        </p>
      </div>
    </div>
    <div class="box-logo" v-else>
      <div>
        <img
          :src="getUrlImage(form.fileLogo, 1)"
          v-if="getUrlImage(form.fileLogo, 1) != ''"
        />
        <p
          v-if="getUrlImage(form.fileLogo, 1) != '' && form.desLogo1"
          class="font-14"
        >
          {{ form.desLogo1 }}
        </p>
      </div>
      <div>
        <img
          :src="getUrlImage(form.fileLogo, 2)"
          v-if="getUrlImage(form.fileLogo, 2) != ''"
        />
        <p
          v-if="getUrlImage(form.fileLogo, 2) != '' && form.desLogo2"
          class="font-14"
        >
          {{ form.desLogo2 }}
        </p>
      </div>
      <div>
        <img
          :src="getUrlImage(form.fileLogo, 3)"
          v-if="getUrlImage(form.fileLogo, 3) != ''"
        />
        <p
          v-if="getUrlImage(form.fileLogo, 3) != '' && form.desLogo3"
          class="font-14"
        >
          {{ form.desLogo3 }}
        </p>
      </div>
    </div>
    <p class="sub-detail">
      The certified organization should accept regular surveillance, if the
      surveillance are qualified, the certification will sustain effective.
      <br />
      Address: (Thailand Headquarter) 368/73 Patio Village Ramintra 1,
      Watcharapol Road, Tharang, Bangkhen, Bangkok 10220 <br />Tel: 6681-8245607
      Website: www.uiccertification.com
    </p>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
    }),
  },
  props: {
    form: {
      type: Object,
      default: {},
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  methods: {
    getUrlImage(logo, number) {
      if (logo) {
        let find = logo.find((row) => row.typelogo == number);
        return find == undefined
          ? ""
          : find.file != undefined
          ? URL.createObjectURL(find.file.raw)
          : this.imageUrl + "certification/" + this.form._id + "/" + find.name;
      }
    },
  },
};
</script>
